.form-label{
    font-weight: 450;
    margin-bottom: 1px;
}

.p-fieldset .p-fieldset-legend{
    padding: 10px;
}

/* DataTableDemo.css */

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-button{
    font-size: 12px;
}

.ui-sidebar-lg{
    width: 700px;
}


/* TabViewDemo.css */

.tabview-demo .tabview-custom i, .tabview-demo .tabview-custom span {
    vertical-align: middle;
}

.tabview-demo .tabview-custom span {
    margin: 0 .5rem;
}
.p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link{
    padding-left: 0;
    margin-bottom:0;
}
.tabview-demo .p-button {
    margin-right: .25rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.toolbaredicion{
    padding: 5px;
    position: sticky;
    position: -webkit-sticky; 
    z-index: 10000; 
    top: 0; 
}

.manito{
    cursor: pointer;
}

.p-sidebar .p-sidebar-header{
    padding: 10px;
}

.p-component{
    font-size: 14px;
    line-height: 16px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item{
    padding: 5px;
}

.p-orderlist .p-orderlist-list{
    padding: 0px;
}

.toolbaredicionmateria{
    z-index: 1000;
}

/*
* DataTabe
*/
.p-datatable .p-datatable-thead > tr > th{
    padding: 10px;
}
.p-datatable .p-datatable-tbody > tr > td{
    padding: 0px 10px;
}

@media (min-width: 1200px){
    legend {
        font-size: 15px;
    }
}

.globalScroll{
    height: 210px;
    overflow-y: scroll;
}
.listaScroll{
    height: auto;
}